<!--
 * @Author       : Hugo
 * @Date         : 2020-12-07 10:36:43
 * @LastEditTime : 2020-12-07 12:00:27
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/SimpleUpload.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div>
    <h3>大文件上传测试页</h3>
    <br><br>
    <uploader
      :options="options"
      class="uploader-example"
      @file-complete="fileComplete"
      @file-added="fileAdded"
      @fil-error="fileError"   
      @change="change"
    >
      <uploader-unsupport></uploader-unsupport>
      <uploader-drop>
        <p>Drop files here to upload or</p>
        <uploader-btn
        >select files</uploader-btn>
        <uploader-btn :attrs="attrs">select images</uploader-btn>
        <uploader-btn :directory="true">select folder</uploader-btn>
      </uploader-drop>
      <uploader-list></uploader-list>
    </uploader>
    <router-link :to="{name: 'test'}">返回上一层</router-link>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        options: {
          // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
          target: '//localhost:3000/upload',
          testChunks: false
        },
        attrs: {
          accept: 'image/*'
        }
      }
    },
    methods: {
      fileAdded(file, event){
        // console.log(file, event);
      },
      fileComplete(rootFile){
        // console.log(rootFile,'rootFile');
      },
      // 某个文件上传失败了
      fileError(rootFile, file, message){
        // console.log(rootFile, file, message)
      },
      change(event){
        // console.log(event);
      },
    }
  }
</script>

<style>
  .uploader-example {
    width: 880px;
    padding: 15px;
    margin: 40px auto 0;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
  }
  .uploader-example .uploader-btn {
    margin-right: 4px;
  }
  .uploader-example .uploader-list {
    max-height: 440px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>