var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("大文件上传测试页")]),
      _c("br"),
      _c("br"),
      _c(
        "uploader",
        {
          staticClass: "uploader-example",
          attrs: { options: _vm.options },
          on: {
            "file-complete": _vm.fileComplete,
            "file-added": _vm.fileAdded,
            "fil-error": _vm.fileError,
            change: _vm.change,
          },
        },
        [
          _c("uploader-unsupport"),
          _c(
            "uploader-drop",
            [
              _c("p", [_vm._v("Drop files here to upload or")]),
              _c("uploader-btn", [_vm._v("select files")]),
              _c("uploader-btn", { attrs: { attrs: _vm.attrs } }, [
                _vm._v("select images"),
              ]),
              _c("uploader-btn", { attrs: { directory: true } }, [
                _vm._v("select folder"),
              ]),
            ],
            1
          ),
          _c("uploader-list"),
        ],
        1
      ),
      _c("router-link", { attrs: { to: { name: "test" } } }, [
        _vm._v("返回上一层"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }